'use client'

import { Button, Group, Paper, Stack, Text, Title } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useSWRConfig } from 'swr'

import { CustomField } from '@/components/commons'
import { api, errorHandler, getUrlString, notificationHandler, Yup, yupSchemas } from '@/lib/utils'
import {
  Form as FormType,
  FormField as FormFieldType,
  FormOption as FormOptionType,
  FormSection as FormSectionType
} from '@/types'

interface Props {
  data: {
    uid: string
    form: FormType
    dateCreated: string
    dateUpdated: string
  }
}

export default function FormResponseForm({ data }: Props) {
  // Hooks
  const router = useRouter()
  const { mutate: mutateGlobal } = useSWRConfig()

  // Constants
  const { formSlug, responseUid, site } = router.query || {}
  const siteSlug = getUrlString(site)

  // Constants
  const values: any = {}
  const yupSchema: any = {}
  data.form.sections.forEach(section => {
    section.fields?.forEach((field: any) => {
      if (field.type === 'bool') values[field.uid] = field.value || false
      if (field.type === 'select') values[field.uid] = field.value?.uid || ''
      else values[field.uid] = field.value
      yupSchemas.createYupSchemaFormResponse(yupSchema, field)
    })
  })

  // States
  const [loading, setLoading] = useState<boolean>(false)

  const initialValues = { ...values }

  const schema = Yup.object().shape(yupSchema)

  // Mantine form
  const form = useForm({
    initialValues,
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true
  })

  // Actions
  const handleUpdate = async () => {
    setLoading(true)

    const newValues = Object.fromEntries(
      Object.entries(form.values).filter(
        ([key, value]) =>
          form.isDirty(key) &&
          value !== null &&
          (typeof value !== 'string' ||
            (typeof value === 'string' && value.indexOf('base64') === -1))
      )
    )
    const uploadValues = Object.fromEntries(
      Object.entries(form.values).filter(
        ([key, value]) =>
          form.isDirty(key) &&
          value !== null &&
          typeof value === 'string' &&
          value.indexOf('base64') !== -1
      )
    )

    await Promise.all(
      Object.entries(uploadValues).map(async ([key, value]) => {
        return await api
          .patch(`/${siteSlug}/forms/${formSlug}/responses/${responseUid}/`, {
            fields: { [key]: value }
          })
          .catch(error => error?.response)
      })
    ).then(async (requests: any) => {
      const failedRequests = requests.filter(
        (request: any) => request?.status && ![200, 201].includes(request?.status)
      )
      if (failedRequests.length > 0) {
        notificationHandler({
          variant: 'error',
          message: `Erro no envio de ${failedRequests.length} arquivo(s)`
        })
      } else {
        return await api
          .patch(`/${siteSlug}/forms/${formSlug}/responses/${responseUid}/`, {
            fields: { ...newValues }
          })
          .then(() => {
            mutateGlobal(`/${siteSlug}/forms/${formSlug}/responses/${responseUid}/`)
            notificationHandler({ message: 'Dados salvos com sucesso!', variant: 'success' })
          })
          .catch(error => {
            const errors = errorHandler(error?.response?.data)
            notificationHandler({
              message: `${errors?.messages?.[0]}` || 'Erro ao atualizar os dados.',
              variant: 'error'
            })
          })
          .finally(() => setLoading(false))
      }
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await handleUpdate()
      .then(() => {
        api
          .post(`/${siteSlug}/forms/${formSlug}/responses/${responseUid}/submit/`)
          .then(() => router.push(`/forms/${formSlug}/success`))
          .catch(error => {
            const errors = errorHandler(error?.response?.data)
            notificationHandler({
              variant: 'error',
              message:
                `${errors?.messages?.[0]}` ||
                'Erro ao enviar o formulário. Verifique se foi preenchido corretamente ou tente novamente mais tarde.'
            })
          })
      })
      .catch(() => {
        notificationHandler({ message: 'Erro ao salvar os dados.', variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} style={{ width: '100%' }}>
      <Stack align="center">
        {data?.form?.sections
          ?.sort((a: FormSectionType, b: FormSectionType) => a.ordering - b.ordering)
          ?.map((section: FormSectionType) => (
            <Paper withBorder key={section.title} p="md" w="100%">
              <Title order={4}>{section.title}</Title>
              <Text size={14} mb={15}>
                {section.description}
              </Text>

              <Stack spacing="sm">
                {section.fields
                  ?.filter((field: FormFieldType) => field.isActive)
                  ?.sort((a: FormFieldType, b: FormFieldType) => a.ordering - b.ordering)
                  ?.map((field: FormFieldType) => {
                    const { config, options = [], value, ...restField } = field
                    const { many, ...restConfig } = config || {}
                    return (
                      <CustomField.Input
                        key={field.uid}
                        value={form.values?.[field.uid] || ''}
                        showLabel
                        config={{ ...restConfig, multiple: many }}
                        options={options.map((option: FormOptionType) => ({
                          label: option.name,
                          value: option.uid
                        }))}
                        {...restField}
                        onChange={(newValue: any) => form.setFieldValue(field.uid, newValue)}
                        inputProps={{ disabled: loading }}
                      />
                    )
                  })}
              </Stack>
            </Paper>
          ))}

        <Group>
          <Button
            type="button"
            color="orange"
            loading={loading}
            disabled={loading || !form.isDirty() || !form.isTouched()}
            onClick={handleUpdate}>
            Salvar Rascunho
          </Button>
          <Button
            type="button"
            color="green"
            loading={loading}
            disabled={loading || !form.isDirty() || !form.isTouched()}
            onClick={handleSubmit}>
            Enviar
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
