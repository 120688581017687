import { Alert, Button, Grid, Group, Text, useMantineTheme } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { IconAlertCircle } from '@tabler/icons'
import { AxiosError } from 'axios'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { KeyedMutator } from 'swr'

import { CityAutocomplete } from '@/containers/autocompletes'
import { api, capitalize, getUrlString, notificationHandler, Yup } from '@/lib/utils'
import { MaskedPhoneNumberWithCode, MaskedPostcode } from '@/lib/utils/masks'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'

import * as AddressFormFields from './Fields'

interface Props {
  values?: any | null
  callback?: KeyedMutator<any> | null
}

interface FormValues {
  label: string
  recipientName: string
  phoneNumber: string
  postcode: string
  city: {
    uid: string
    name: string
    state: {
      name: string
      code: string
    }
  }
  line1: string
  line4: string
  line2: string
  line3: string
}

export default function Checkout({ values, callback }: Props) {
  // Hooks
  const router = useRouter()
  const { site: siteSlug, basketUid, type: typeQuery, callbackUrl: urlQuery } = router.query || {}
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableFields, setDisableFields] = useState({ line1: true, line2: true, city: true })
  const [postcodeError, setPostcodeError] = useState<any>(null)
  const [postcodeUpdate, setPostcodeUpdate] = useState<{ old: string; new: string } | null>(null)

  // Constants
  const callbackUrl = getUrlString(urlQuery)
  const type = getUrlString(typeQuery)
  const redirectHref = callbackUrl
    ? `/checkout/${basketUid}/addresses?type=${type}&callbackUrl=${callbackUrl}`
    : `/checkout/${basketUid}/addresses?type=${type}`

  const postcodeRegex = /^(\d{4,5}-?\d{3})/

  const city = values?.cityUid && {
    uid: values?.cityUid || '',
    name: values?.city || '',
    state: { name: values?.state || '', code: values?.stateCode || '' }
  }

  const address = {
    label: capitalize(values?.label as string) || 'Casa',
    recipientName: values?.recipientName || '',
    phoneNumber: values?.phoneNumber || '',
    postcode: values?.postcode || '',
    city: city || null,
    line1: values?.line1 || '',
    line4: values?.line4 || '',
    line3: values?.line3 || '',
    line2: values?.line2 || ''
  }

  // Validation schema
  const schema = Yup.object().shape({
    // Address
    label: Yup.string().required(),
    recipientName: Yup.string(),
    phoneNumber: Yup.string(),
    postcode: Yup.string().matches(postcodeRegex, 'Digite um CEP válido').required(),
    city: Yup.object().nullable().required(),
    line1: Yup.string().min(3).required(),
    line4: Yup.string().required(),
    line2: Yup.string().min(2).required(),
    line3: Yup.string()
  })

  // Mantine form
  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: { ...address }
  })

  // Actions
  const errorHandler = (error: AxiosError) => {
    const { response } = error
    const errorData = response?.data || ({} as any)
    const errorKey = Object.keys(errorData)[0]
    return notificationHandler({
      variant: 'error',
      message: errorData[errorKey] || 'Houve um erro durante o registro dos dados do endereço'
    })
  }

  const postcodeCallback = (response: any, status: number | undefined) => {
    const requiredFieldErrorMessage = 'Campo obrigatório'
    if (form.isValid('postcode')) {
      setPostcodeError(status !== 200 ? response?.data || 'error' : null)
    }
    if (status === 200) {
      const postcodeResponse = response?.data?.postcode
      setPostcodeUpdate(
        !!postcodeResponse && postcodeResponse !== form.values.postcode
          ? { old: form.values.postcode, new: postcodeResponse }
          : null
      )
      form.setValues({
        ...form.values,
        ...(!!postcodeResponse && { postcode: postcodeResponse }),
        line1: response?.data?.line1 || '',
        line2: response?.data?.line2 || '',
        line3: '',
        line4: '',
        city: {
          uid: response?.data?.cityUid,
          name: response?.data?.city,
          state: { name: response?.data?.state, code: response?.data?.stateCode }
        }
      })
      setDisableFields({
        line1: !!response.data?.line1,
        line2: !!response.data?.line2,
        city: !!response.data?.cityUid
      })

      if (response.data?.line1) form.clearFieldError('line1')
      else form.setFieldError('line1', requiredFieldErrorMessage)

      if (response.data?.line2) form.clearFieldError('line2')
      else form.setFieldError('line2', requiredFieldErrorMessage)

      if (response.data?.cityUid) form.clearFieldError('city')
      else form.setFieldError('city', requiredFieldErrorMessage)

      form.setFieldError('line4', requiredFieldErrorMessage)
    } else {
      form.setValues({ ...form.values, line1: '', line2: '', line3: '', line4: '', city: null })
      form.setErrors({
        line1: requiredFieldErrorMessage,
        line2: requiredFieldErrorMessage,
        line4: requiredFieldErrorMessage,
        city: requiredFieldErrorMessage
      })
      setDisableFields({ line1: true, line2: true, city: true })
    }

    return null
  }

  const setCheckoutAddress = (uid: string) => {
    if (type !== 'billingAddress' && type !== 'shippingAddress') return null
    return api
      .patch(`/${siteSlug}/checkout/${basketUid}/`, { [type]: uid })
      .then(() => router.push(redirectHref))
      .catch(errorHandler)
      .finally(() => setIsSubmitting(false))
  }

  const handleSubmit = async (newValues: FormValues) => {
    setIsSubmitting(true)

    const newAddress = {
      label: newValues.label,
      recipientName: newValues.recipientName,
      phoneNumber: newValues.phoneNumber,
      postcode: newValues.postcode,
      city: newValues.city?.uid,
      line1: newValues.line1,
      line4: newValues.line4,
      line2: newValues.line2,
      line3: newValues.line3
    }

    // Update or create
    if (values?.uid) {
      api
        .patch(`/${siteSlug}/addresses/${values.uid}/`, newAddress)
        .then(response => {
          callback?.()
          notificationHandler({ variant: 'success', message: 'Endereço atualizado com sucesso' })
          return response?.data?.uid
        })
        .then(setCheckoutAddress)
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    } else {
      api
        .post(`/${siteSlug}/addresses/`, newAddress)
        .then(response => {
          callback?.()
          notificationHandler({ variant: 'success', message: 'Endereço registrado com sucesso' })
          return response?.data?.uid
        })
        .then(setCheckoutAddress)
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <AddressFormFields.Label
            inputProps={{ ...form.getInputProps('label'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} hidden={!postcodeError}>
          <Alert title="CEP não localizado" icon={<IconAlertCircle size={16} />} color="red">
            <Text>
              Não localizamos o CEP <strong>{form.values.postcode}</strong> na base de endereços dos
              Correios.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={12} hidden={!postcodeUpdate}>
          <Alert title="CEP atualizado" icon={<IconAlertCircle size={16} />}>
            <Text>
              O CEP {postcodeUpdate?.old} foi alterado para <strong>{postcodeUpdate?.new}</strong>.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.RecipientName
            inputProps={{ ...form.getInputProps('recipientName'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.PhoneNumber
            inputProps={{
              ...form.getInputProps('phoneNumber'),
              component: MaskedPhoneNumberWithCode,
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Postcode
            callback={postcodeCallback}
            inputProps={{
              ...form.getInputProps('postcode'),
              component: MaskedPostcode,
              onInput: () => {
                setPostcodeError(null)
                setPostcodeUpdate(null)
              },
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <CityAutocomplete
            selected={form.values.city}
            onChange={newValue => form.setFieldValue('city', newValue)}
            inputProps={{
              ...form.getInputProps('city'),
              onInput: () => form.clearFieldError('city'),
              disabled: disableFields.city || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={7}>
          <AddressFormFields.Line1
            inputProps={{
              ...form.getInputProps('line1'),
              disabled: disableFields.line1 || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={5}>
          <AddressFormFields.Line4
            inputProps={{ ...form.getInputProps('line4'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Line2
            inputProps={{
              ...form.getInputProps('line2'),
              disabled: disableFields.line2 || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Line3
            inputProps={{ ...form.getInputProps('line3'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} mt="xl">
          <Group position="apart" spacing="md">
            <Button
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              variant="outline"
              disabled={isSubmitting || isLoadingGlobal}
              component={Link}
              href={redirectHref}>
              Voltar
            </Button>
            <Button
              type="submit"
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              disabled={!form.isValid() || !form.isDirty() || isLoadingGlobal}
              loading={isSubmitting}>
              {values?.uid ? 'Salvar' : 'Adicionar'}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
