import { Alert, Button, Grid, Group, Text, useMantineTheme } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { IconAlertCircle } from '@tabler/icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useSWRConfig } from 'swr'

import { CityAutocomplete } from '@/containers/autocompletes'
import { api, capitalize, errorHandler, notificationHandler, Yup } from '@/lib/utils'
import { MaskedPhoneNumberWithCode, MaskedPostcode } from '@/lib/utils/masks'
import { useRouterLoading } from '@/providers/RouterLoadingProvider'

import * as AddressFormFields from './Fields'

interface Props {
  values?: any | null
  callback?: () => void
}

interface FormValues {
  label: string
  recipientName: string
  phoneNumber: string
  postcode: string
  city: {
    uid: string
    name: string
    state: {
      name: string
      code: string
    }
  }
  line1: string
  line4: string
  line2: string
  line3: string
}

export default function Address({ values, callback }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const { mutate: mutateGlobal } = useSWRConfig()
  const { isLoading: isLoadingGlobal } = useRouterLoading()

  // States
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableFields, setDisableFields] = useState({ line1: true, line2: true, city: true })
  const [postcodeError, setPostcodeError] = useState<any>(null)
  const [postcodeUpdate, setPostcodeUpdate] = useState<{ old: string; new: string } | null>(null)

  // Constants
  const { site: siteSlug } = router.query || {}
  const postcodeRegex = /^(\d{4,5}-?\d{3})/

  const city = values?.cityUid && {
    uid: values?.cityUid || '',
    name: values?.city || '',
    state: { name: values?.state || '', code: values?.stateCode || '' }
  }

  const initialValues = {
    label: capitalize(values?.label as string) || 'Casa',
    recipientName: values?.recipientName || '',
    phoneNumber: values?.phoneNumber || '',
    postcode: values?.postcode || '',
    city: city || null,
    line1: values?.line1 || '',
    line4: values?.line4 || '',
    line3: values?.line3 || '',
    line2: values?.line2 || ''
  }

  // Validation schema
  const schema = Yup.object().shape({
    label: Yup.string().required(),
    recipientName: Yup.string(),
    phoneNumber: Yup.string(),
    postcode: Yup.string().matches(postcodeRegex, 'Digite um CEP válido').required(),
    city: Yup.object().nullable().required(),
    line1: Yup.string().min(3).required(),
    line4: Yup.string().required(),
    line2: Yup.string().min(2).required(),
    line3: Yup.string()
  })

  // Mantine form
  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues
  })

  // Actions
  const postcodeCallback = (response: any, status: number | undefined) => {
    const requiredFieldErrorMessage = 'Campo obrigatório'
    if (form.isValid('postcode')) {
      setPostcodeError(status !== 200 ? response?.data || 'error' : null)
    }
    if (status === 200) {
      const postcodeResponse = response?.data?.postcode
      setPostcodeUpdate(
        !!postcodeResponse && postcodeResponse !== form.values.postcode
          ? { old: form.values.postcode, new: postcodeResponse }
          : null
      )
      form.setValues({
        ...form.values,
        ...(!!postcodeResponse && { postcode: postcodeResponse }),
        line1: response?.data?.line1 || '',
        line2: response?.data?.line2 || '',
        line3: '',
        line4: '',
        city: {
          uid: response?.data?.cityUid,
          name: response?.data?.city,
          state: { name: response?.data?.state, code: response?.data?.stateCode }
        }
      })
      setDisableFields({
        line1: !!response.data?.line1,
        line2: !!response.data?.line2,
        city: !!response.data?.cityUid
      })

      if (response.data?.line1) form.clearFieldError('line1')
      else form.setFieldError('line1', requiredFieldErrorMessage)

      if (response.data?.line2) form.clearFieldError('line2')
      else form.setFieldError('line2', requiredFieldErrorMessage)

      if (response.data?.cityUid) form.clearFieldError('city')
      else form.setFieldError('city', requiredFieldErrorMessage)

      form.setFieldError('line4', requiredFieldErrorMessage)
    } else {
      form.setValues({ ...form.values, line1: '', line2: '', line3: '', line4: '', city: null })
      form.setErrors({
        line1: requiredFieldErrorMessage,
        line2: requiredFieldErrorMessage,
        line4: requiredFieldErrorMessage,
        city: requiredFieldErrorMessage
      })
      setDisableFields({ line1: true, line2: true, city: true })
    }

    return null
  }

  const handleSubmit = async (newValues: FormValues) => {
    setIsSubmitting(true)
    const addressesEndpoint = `/${siteSlug}/addresses/`

    // Update or create
    if (values?.uid) {
      api
        .patch(`${addressesEndpoint}${values.uid}/`, { ...newValues, city: newValues.city?.uid })
        .then(response => {
          callback?.()
          mutateGlobal(addressesEndpoint)
          notificationHandler({ variant: 'success', message: 'Endereço atualizado com sucesso' })
          return response?.data?.uid
        })
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    } else {
      api
        .post(addressesEndpoint, { ...newValues, city: newValues.city?.uid })
        .then(response => {
          callback?.()
          mutateGlobal(addressesEndpoint)
          notificationHandler({ variant: 'success', message: 'Endereço registrado com sucesso' })
          return response?.data?.uid
        })
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <AddressFormFields.Label
            inputProps={{ ...form.getInputProps('label'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} hidden={!postcodeError}>
          <Alert title="CEP não localizado" icon={<IconAlertCircle size={16} />} color="red">
            <Text>
              Não localizamos o CEP <strong>{form.values.postcode}</strong> na base de endereços dos
              Correios.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={12} hidden={!postcodeUpdate}>
          <Alert title="CEP atualizado" icon={<IconAlertCircle size={16} />}>
            <Text>
              O CEP {postcodeUpdate?.old} foi alterado para <strong>{postcodeUpdate?.new}</strong>.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.RecipientName
            inputProps={{ ...form.getInputProps('recipientName'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.PhoneNumber
            inputProps={{
              ...form.getInputProps('phoneNumber'),
              component: MaskedPhoneNumberWithCode,
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Postcode
            callback={postcodeCallback}
            inputProps={{
              ...form.getInputProps('postcode'),
              component: MaskedPostcode,
              onInput: () => {
                setPostcodeError(null)
                setPostcodeUpdate(null)
              },
              disabled: isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <CityAutocomplete
            selected={form.values.city}
            onChange={newValue => form.setFieldValue('city', newValue)}
            inputProps={{
              ...form.getInputProps('city'),
              onInput: () => form.clearFieldError('city'),
              disabled: disableFields.city || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={7}>
          <AddressFormFields.Line1
            inputProps={{
              ...form.getInputProps('line1'),
              disabled: disableFields.line1 || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={5}>
          <AddressFormFields.Line4
            inputProps={{ ...form.getInputProps('line4'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Line2
            inputProps={{
              ...form.getInputProps('line2'),
              disabled: disableFields.line2 || isSubmitting
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <AddressFormFields.Line3
            inputProps={{ ...form.getInputProps('line3'), disabled: isSubmitting }}
          />
        </Grid.Col>
        <Grid.Col span={12} mt="xl">
          <Group position="right">
            <Button
              type="submit"
              size={isXs ? 'sm' : 'md'}
              fullWidth={!!isXs}
              disabled={!form.isValid() || !form.isDirty() || isLoadingGlobal}
              loading={isSubmitting}>
              {values?.uid ? 'Salvar' : 'Adicionar'}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
